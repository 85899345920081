import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout'
import DeviceTableHandler from '../handlers/deviceTable-handler';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const DevicesGrid: React.FC = () => {

  const layout: Layout[] = [
    { i: 'a', x: 1, y: 1, w: 7, h: 4, minW: 7, minH: 4 },
    { i: 'b', x: 1, y: 7, w: 7, h: 5, minW: 7, minH: 2 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="a" style={{ minHeight: "500px" }} className='card-gauge'>
        <DeviceTableHandler />
      </div>
      <div key="b" style={{ minHeight: "500px" }} className='card-gauge'>
      </div>
    </ResponsiveGridLayout>
  );
};

export default DevicesGrid;