import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import axios from 'axios';
import { buildingAtom } from '../atoms/buildingAtom';
import { Building } from '../defs/building-def';
import BuildingGraph from '../charts-graphs/buildingGraph';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment-timezone';

export default function BuildingGraphHandler() {
  const [building, setBuilding] = useAtom(buildingAtom);

  // State for start and end DateTime, defaulting to the last 24 hours
  const [startDateTime, setStartDateTime] = useState<Moment>(moment().subtract(24, 'hour').tz('America/New_York'));
  const [endDateTime, setEndDateTime] = useState<Moment>(moment().tz('America/New_York'));

  // Effect to fetch data when DateTime values change
  useEffect(() => {
    refetch();
  }, [startDateTime, endDateTime]);

  const { isFetching, isError, error, refetch } = useQuery({
    queryKey: ['buildingGraph', startDateTime, endDateTime],
    staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
    refetchInterval: 1000 * 60 * 1, // Refetch data every 2 minutes
    queryFn: async () => {
      const response = await axios.get<Building[]>('https://odp.golf/api/buildingdata-graph', {
        params: {
          start: startDateTime.toISOString(),
          end: endDateTime.toISOString(),
        },
      });
      const data = response.data;
      console.log('buildinghandler: ', data);
      UpdateBuilding(data);
      console.log('BuildingData: ', building);
      return data;
    },
  });

  function UpdateBuilding(data: Building[]) {
    const updatedBuilding = data?.map(bldg => ({
      ...bldg,
    }));
    setBuilding(updatedBuilding);
  }

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {renderLoadingOrError()}
      <BuildingGraph />
      <div className="dateTimePickerContainer">
        <DateTimePicker
          label="Start DateTime"
          value={startDateTime}
          onChange={(newValue) => setStartDateTime(newValue?.tz('America/New_York') ?? moment().subtract(24, 'hour').tz('America/New_York'))}
          className="dateTimePickerInput"
          slotProps={{
            textField: {
              className: 'dateTimePickerInput',
              inputProps: {
                className: 'dateTimePickerInput',
              },
            },
            popper: {
              className: 'dateTimePickerPopper',
            },
          }}
        />
        <DateTimePicker
          label="End DateTime"
          value={endDateTime}
          onChange={(newValue) => setEndDateTime(newValue?.tz('America/New_York') ?? moment().tz('America/New_York'))}
          className="dateTimePickerInput"
          slotProps={{
            textField: {
              className: 'dateTimePickerInput',
              inputProps: {
                className: 'dateTimePickerInput',
              },
            },
            popper: {
              className: 'dateTimePickerPopper',
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
}



