import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useAtom } from 'jotai';
import { chargingAtom, toggleCharging } from '../atoms/chargingAtom';
import { CircularProgress, Typography } from '@mui/material';
import { Button } from '@mui/material';

const ChargingGraph = () => {
  const [chargingData] = useAtom(chargingAtom);
  const [viewType] = useAtom(toggleCharging);
  const [scaleMax, setScaleMax] = useState(10);
  const [scaleMin, setScaleMin] = useState(-5);
  const [stateMin, setStateMin] = useState(-1);

  useEffect(() => {
    const handleResize = () => {
      const chartElement = document.querySelector('.echarts-for-react');
      if (chartElement) {
        const echartsInstance = (chartElement as any).__ECharts__;
        if (echartsInstance) {
          echartsInstance.resize();
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!chargingData) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}>
        <CircularProgress style={{ color: 'goldenrod' }} />
        <h3 style={{ color: 'white', marginTop: '20px' }}>Waiting for data...</h3>
      </div>
    );
  }

  const dataToDisplay = viewType === 'CPower' ? chargingData.CPower : chargingData.CEnergy;
  const cData = dataToDisplay.length > 0 ? dataToDisplay : [];
  const cStateData = chargingData.CState || [];

  const enumColors = {
    Fault: 'firebrick',
    Empty: 'grey',
    Wait: 'chocolate',
    Charging: 'steelblue',
    Done: 'seagreen',
    Testing: 'slateblue',
    Status: 'whitesmoke',
  };

  const stateLabels = Object.keys(enumColors) as Array<keyof typeof enumColors>;

  const getBarColor = (value: number) => {
    if (value < 2) {
      return 'firebrick';
    } else if (value > 25) {
      return 'seagreen';
    } else {
      return viewType === 'CPower' ? 'goldenrod' : 'teal';
    }
  };

  const getStackColor = (state: number) => {
    return enumColors[stateLabels[state]] || 'gray';
  };

  const toggleScale = () => {
    setScaleMax((prevMax) => (prevMax === 10 ? 1000 : 10));
    setScaleMin((prevMin) => (prevMin === -5 ? -500 : -5));
    setStateMin((prevStateMin) => (prevStateMin === -1 ? -100 : -1));
  };

  const option = {
    title: {
      text: `Charging: ${viewType === 'CPower' ? 'Power': 'Energy'} per Channel`,
      textStyle: {
        color: 'white',
      },
    },
    xAxis: {
      type: 'category',
      data: cData.map((_, index) => `${index + 1}`),
      axisLabel: {
        color: "white",
        rotate: 45,
        interval: 0 
      }
    },
    yAxis: {
      type: 'value',
      name: viewType === 'CPower' ? 'Watts' : 'KWh',
      max: viewType === 'CPower' ? scaleMax : 10,
      min: viewType === 'CPower' ? scaleMin : -5,
      axisLabel: {
        color: "white"
      }
    },
    series: [
      {
        name: 'Bar',
        data: cData.map((value) => ({
          value,
          itemStyle: {
            color: getBarColor(value),
          },
        })),
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        },
        stack: 'total',
      },
      {
        name: 'State',
        data: cStateData.map((state) => ({
          value: viewType === 'CPower' ? stateMin : -1, 
          itemStyle: {
            color: getStackColor(state),
          },
        })),
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          position: 'bottom',
          formatter: '{b}',
          color: 'white',
        },
        tooltip: {
          show: false
        }
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { 
        type: 'shadow'
      },
      formatter: (params: any) => {
        const barValue = params.find((param: any) => param.seriesName === 'Bar')?.data?.value || 0;
        return `Value: ${barValue}`;
      }
    },
    grid: { 
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={toggleScale}
        disabled={viewType !== 'CPower'}
        style={{
          backgroundColor: scaleMax === 10 ? 'steelblue' : 'darkorange',
          color: 'white',
        }}
      >
        Toggle Scale to {scaleMax === 10 ? '1000' : '10'}
      </Button>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <Typography style = {{ color: 'white' }}>Charger Status:</Typography>
        {stateLabels.map(label => (
          <div key={label} style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: enumColors[label], marginRight: '5px' }}></div>
            <span style={{ color: 'white' }}>{label}</span>
          </div>
        ))}
      </div>
      <ReactECharts 
        option={option} 
        className="cancelSelectorName" 
        style={{ height: '500px', width: '100%' }} 
        opts={{ renderer: 'canvas', width: 'auto', height: 'auto' }}
      />
    </div>
  );
};

export default ChargingGraph;

