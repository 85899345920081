import React from 'react';
import { useAtom } from 'jotai';
import { mapCenterAtom } from '../atoms/mapAtoms';
import Button from "@mui/material/Button";

const MapCenterButton = () => {
  const [mapCenter, setMapCenter] = useAtom(mapCenterAtom);
  const intergridLocation = { lat: 42.81149, lng: -71.86577 };
  const accLocation = { lat: 42.82941, lng: -71.60524 };

  // Check if the current location is Intergrid
  const isAtIntergrid = mapCenter.lat === intergridLocation.lat && mapCenter.lng === intergridLocation.lng;

  const toggleLocation = () => {
    console.log('toggle has been clicked');
    if (isAtIntergrid) {
      // If at Intergrid, switch to ACC
      setMapCenter(accLocation);
    } else {
      // If not at Intergrid (i.e., at ACC), switch to Intergrid
      setMapCenter(intergridLocation);
    }
  };

  return (
    <Button
      onClick={toggleLocation}
      style={{
        backgroundColor: isAtIntergrid ? 'steelblue' : 'goldenrod',
        color: 'white'
      }}
    >
      {isAtIntergrid ? 'ACC' : 'Intergrid'}
    </Button>
  );
};

export default MapCenterButton;

