import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, styled, tableCellClasses } from '@mui/material';
import { allDeviceAtom } from '../atoms/allDeviceAtom';
import Device from '../defs/device-def';
import { useNavigate } from 'react-router-dom';

type Order = 'asc' | 'desc';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'dimgrey',
    color: theme.palette.common.white,
    '&:hover': {
      color: 'goldenrod',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CustomTableSortLabel = styled(TableSortLabel)(({ theme, active }) => ({
  color: 'inherit',
  '&:hover': {
    color: 'orange', 
    '& .MuiTableSortLabel-icon': {
      color: 'orange',
    },
  },
  ...(active && {
    color: 'lightblue !important',
    '& .MuiTableSortLabel-icon': {
      color: 'lightblue !important', 
    },
  }),
}));

const DeviceTable: React.FC = () => {
  const [allDevices] = useAtom(allDeviceAtom);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Device>('deviceID');
  const navigate = useNavigate();

  const handleRequestSort = (property: keyof Device) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortComparator = (a: Device, b: Device, orderBy: keyof Device) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedDevices = [...allDevices].sort((a, b) => {
    return order === 'asc'
      ? sortComparator(a, b, orderBy)
      : -sortComparator(a, b, orderBy);
  });

  const handleDeviceClick = (deviceID: string) => {
    navigate(`/${deviceID}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            {[
              'deviceID', 
              'name',
              'SOC', 
              'TbattLvl', 
              'lastRx', 
              'RSSI', 
              'lastIncidentCode', 
              'deviceEUI', 
              'carUID'
            ].map((column) => (
              <StyledTableCell key={column}>
                <CustomTableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={() => handleRequestSort(column as keyof Device)}
                >
                  {column}
                </CustomTableSortLabel>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {sortedDevices.map((device) => (
            <TableRow key={device.deviceID}>
              <TableCell
                onClick={() => handleDeviceClick(device.deviceID)}
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              >
                {device.deviceID}
              </TableCell>
              <TableCell>{device.name}</TableCell>
              <TableCell>{device.SOC}</TableCell>
              <TableCell>{device.TbattLvl}</TableCell>
              <TableCell>{device.lastRx}</TableCell>
              <TableCell>{device.RSSI}</TableCell>
              <TableCell>{device.lastIncidentCode}</TableCell>
              <TableCell>{device.deviceEUI}</TableCell>
              <TableCell>{device.carUID}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeviceTable;



