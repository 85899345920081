export default function ConvertTime(timestamp: number | string): string {
  const date = new Date(timestamp);

  // Adjust for EST (UTC-5) or EDT (UTC-4) considering daylight saving time
  const isDST = date.getMonth() > 2 && date.getMonth() < 10; // Simplified DST check (April to October)
  const offset = isDST ? -4 : -5; // EDT (UTC-4) in DST, EST (UTC-5) otherwise
  date.setHours(date.getUTCHours() + offset);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  // const year = date.getFullYear().toString().substr(-2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds} ${month}/${day}`;
}
