import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import MapGrid from './components/grids/golfMapGrid';
import DevicesGrid from './components/grids/devicesGrid';
import ChargingGrid from './components/grids/chargingGrid';
import Navbar from './components/navigation/navbar';
import ProtectedRoute from './components/protectedRoute';
import packageJson from '../package.json';

const WithNavbarLayout: React.FC = () => (
  <>
    <Navbar />
    <div>
      <Outlet />
    </div>
    <p className="d-flex text-center justify-content-center">
      v{packageJson.version} Intergrid LLC©2024
    </p>
  </>
);


function App() {
  return (
    <Router>
      <Routes>
        {/* with navbar */}
        <Route element={<WithNavbarLayout/>}>
          <Route path="/" element={
            <ProtectedRoute>
              <MapGrid />
            </ProtectedRoute>
          } />
          <Route path="/:deviceID?" element={
            <ProtectedRoute>
              <MapGrid />
            </ProtectedRoute>
          } />
          <Route path="/devices" element={
            <ProtectedRoute>
              <DevicesGrid />
            </ProtectedRoute>
          } />
          <Route path="/charging" element={
            <ProtectedRoute>
              <ChargingGrid />
            </ProtectedRoute>
          } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
