/* eslint-disable complexity */
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';

import {GoogleMapsContext, useMapsLibrary} from '@vis.gl/react-google-maps';

import type {Ref} from 'react';

type PolylineEventProps = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
  onMouseOver?: (e: google.maps.MapMouseEvent) => void;
  onMouseOut?: (e: google.maps.MapMouseEvent) => void;
};

type PolylineCustomProps = {
  /**
   * this is an encoded string for the path, will be decoded and used as a path
   */
  encodedPath?: string;
};

export type PolylineProps = google.maps.PolylineOptions &
  PolylineEventProps &
  PolylineCustomProps;

export type PolylineRef = Ref<google.maps.Polyline | null>;

function usePolyline(props: PolylineProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    encodedPath,
    ...polylineOptions
  } = props;
  // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)
  const callbacks = useRef<Record<string, (e: unknown) => void>>({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut
  });

  const geometryLibrary = useMapsLibrary('geometry');

  const polyline = useRef<google.maps.Polyline | null>(null);

  useEffect(() => {
    polyline.current = new google.maps.Polyline();
    console.log('Polyline created:', polyline.current);
    return () => {
      if (polyline.current) {
        polyline.current.setMap(null);
        google.maps.event.clearInstanceListeners(polyline.current);
      }
    };
  }, []);

  // Update PolylineOptions
  useEffect(() => {
    if (polyline.current) {
      polyline.current.setOptions(polylineOptions);
    }
  }, [polylineOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // Update the path with the encodedPath
  useEffect(() => {
    if (encodedPath && geometryLibrary && polyline.current) {
      console.log('Decoding and setting path for encodedPath:', encodedPath);
      const path = geometryLibrary.encoding.decodePath(encodedPath);
      polyline.current.setPath(path);
    }
  }, [encodedPath, geometryLibrary]);

  // Add Polyline to map
  useEffect(() => {
    if (map && polyline.current) {
      polyline.current.setMap(map);
    } else if (polyline.current) {
      polyline.current.setMap(null);
    }
  }, [map]);

  // Attach and re-attach event-handlers
  useEffect(() => {
    if (polyline.current) {
      const gme = google.maps.event;
      [
        ['click', 'onClick'],
        ['drag', 'onDrag'],
        ['dragstart', 'onDragStart'],
        ['dragend', 'onDragEnd'],
        ['mouseover', 'onMouseOver'],
        ['mouseout', 'onMouseOut']
      ].forEach(([eventName, eventCallback]) => {
        gme.addListener(polyline.current!, eventName, (e: google.maps.MapMouseEvent) => {
          const callback = callbacks.current[eventCallback];
          if (callback) callback(e);
        });
      });

      return () => {
        if (polyline.current) {
          gme.clearInstanceListeners(polyline.current);
        }
      };
    }
  }, []);

  return polyline.current;
}

/**
 * Component to render a polyline on a map
 */
export const Polyline = forwardRef((props: PolylineProps, ref: PolylineRef) => {
  const polyline = usePolyline(props);

  useImperativeHandle(ref, () => polyline, [polyline]);

  return null;
});
