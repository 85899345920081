import React, { useState, MouseEvent, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Typography, Toolbar, Box, AppBar, Drawer, List, ListItem, Divider, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MapIcon from '@mui/icons-material/Map';
import DevicesIcon from '@mui/icons-material/Devices';
import ChargingStationIcon from '@mui/icons-material/EvStation';
import { useAuth0 } from '@auth0/auth0-react';

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (((event as KeyboardEvent).key === 'Tab') || ((event as KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <Button
            component={Link}
            to="/"
            startIcon={<MapIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Map
          </Button>
        </ListItem>
        <ListItem>
          <Button
            component={Link}
            to="/devices"
            startIcon={<DevicesIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Devices
          </Button>
        </ListItem>
        <ListItem>
          <Button
            component={Link}
            to="/charging"
            startIcon={<ChargingStationIcon />}
            sx={{ justifyContent: 'flex-start', width: '100%' }}
          >
            Charging
          </Button>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#1e212a', paddingTop: '8px' }}>
        <Toolbar>
          {/* Logo */}
          <img
            src="/logos/ODP_Logo.png"
            alt="Company Logo"
            style={{ width: '50px', height: 'auto', marginRight: '16px' }}
          />

          {/* IconButton for Drawer Toggle */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'block', sm: 'none' }, marginRight: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Links for larger screens */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', marginRight: 'auto' }}>
            <Typography variant="h6" component="div" sx={{ color: 'white', marginRight: '20px' }}>
              <Link to="/" style={{ color: '#FFFF00', textDecoration: 'none' }}>Map</Link>
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: 'white', marginRight: '20px' }}>
              <Link to="/devices" style={{ color: '#FFFF00', textDecoration: 'none' }}>Devices</Link>
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: 'white' }}>
              <Link to="/charging" style={{ color: '#FFFF00', textDecoration: 'none' }}>Charging</Link>
            </Typography>
          </Box>

          {/* Account Icon with Dropdown Menu */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ color: '#FFFF00' }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {isAuthenticated ? (
                <MenuItem onClick={() => {
                  logout();
                  window.location.href = window.location.origin;
                  handleClose();
                }}>
                  Logout
                </MenuItem>
              ) : (
                <MenuItem onClick={() => {
                  loginWithRedirect();
                  handleClose();
                }}>
                  Login
                </MenuItem>
              )}
            </Menu>
          </div>

          {/* Drawer Component */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {drawerList()}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

